import http, { request } from "@config/http";
import { Page, Pagination } from "@services/common/model/page.model";
import { RoleEnum } from "@services/user/enum/user.enum";

import {
  LoginPayloadModel,
  LoginResponseModel,
  UserModel,
  PasswordPayloadModel,
} from "./model/user.model";

export const loginApi = async (params: LoginPayloadModel) => {
  return http.post<null, Page<LoginResponseModel>>("login", {
    ...params,
  });
};

export const logoutApi = async () => {
  return http.delete<null, Page<null>>("logout", {});
};

export const getUserInfoApi = async () => {
  return request.get<null, Page<UserModel>>("user/info", {});
};

export const changePasswordApi = async (params: PasswordPayloadModel) => {
  return http.post<null, Page<null>>(`user/change_password`, {
    ...params,
  });
};
