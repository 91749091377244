import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@state/store";
import { Navigate } from "react-router-dom";
import { useRequest } from "ahooks";
import LoadIndicator from "@components/load-indicator";
import { getUserInfoApi } from "@services/user/user.api";
import { setUser } from "@state/global/reducer";

interface Prop {
  children: any;
}
const LoginGuard: React.FC<Prop> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data, loading } = useRequest(getUserInfoApi);

  useEffect(() => {
    if (data && data.data) {
      dispatch(setUser(data.data));
    }
  }, [data]);

  if (loading) {
    return <LoadIndicator />;
  } else if (!loading && data && data.code === 200) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default LoginGuard;
