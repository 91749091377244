import axios, { AxiosError } from "axios";
import { Toast } from "antd-mobile";
import { getLocalStorage } from "@utils/localstorage";
import { LoginEnum } from "@services/user/enum/user.enum";
import { LoginResponseModel } from "@services/user/model/user.model";
import { Page } from "@services/common/model/page.model";
import { API_HOST } from "@config/global.config";

export const axiosInstance = axios.create({
  baseURL: `${API_HOST}/api/`,
});

export const request = axios.create({
  baseURL: `${API_HOST}/api/`,
});

request.interceptors.request.use(
  function (config) {
    const loginInfo = getLocalStorage(LoginEnum.Token) as LoginResponseModel;
    if (loginInfo) {
      config.headers.Authorization = loginInfo.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    // eslint-disable-next-line
    const { data } = response;

    // eslint-disable-next-line
    return data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    const loginInfo = getLocalStorage(LoginEnum.Token) as LoginResponseModel;
    if (loginInfo) {
      config.headers.Authorization = loginInfo.token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // eslint-disable-next-line
    const { status, data } = response;
    // eslint-disable-next-line
    const { code, message } = data as Page<null>;

    if (code !== 200 && code !== 401) {
      Toast.show({
        icon: "fail",
        content: message,
      });
    }

    if (code === 401) {
      window.location.href = "/login";
    }

    // eslint-disable-next-line
    return data;
  },
  function (error: AxiosError<{ message: string }>) {
    if (error.response) {
      const response = error.response;
      Toast.show({
        icon: "fail",
        content: response.data.message,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
