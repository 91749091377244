import { Navigate } from "react-router-dom";
import { isMobile } from "@utils/is-mobile.util";

interface Prop {
  children: any;
}

const BrowserDetectGuard: React.FC<Prop> = ({ children }) => {
  if (isMobile()) {
    return <Navigate to="/tasks" replace />;
  } else {
    return children;
  }
};

export default BrowserDetectGuard;
