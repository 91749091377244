import React from "react";
import { Navigate } from "react-router-dom";
import { useRequest } from "ahooks";
import LoadIndicator from "@components/load-indicator";
import { getUserInfoApi } from "@services/user/user.api";

interface Prop {
  children: any;
}
const LogoutGuard: React.FC<Prop> = ({ children }) => {
  const { data, loading } = useRequest(getUserInfoApi);

  if (loading) {
    return <LoadIndicator />;
  } else if (!loading && data && data.code === 200) {
    return <Navigate to="/tasks" replace />;
  } else {
    return children;
  }
};

export default LogoutGuard;
