export const domain = `${window.location.protocol}//${window.location.host}`;

export const passwordEncryptionKey = "1234123412ABCDEF";

export const API_HOST = `${process.env.REACT_APP_API_HOST!}`;

export const LOGIN_PAGE = `${domain}/login`;

export const SIGNUP_PAGE = `${domain}/register`;

export const ACCOUNT_PAGE = `${domain}/account`;
