import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

const SupportPage = React.lazy(() => import("./pages/support"));
const LoginPage = React.lazy(() => import("./pages/user/login"));
const TaskListPage = React.lazy(() => import("./pages/task"));
const TaskFormPage = React.lazy(() => import("./pages/task/task-form"));
const ResetPasswordPage = React.lazy(
  () => import("./pages/user/reset-password")
);

import Iframe from "./pages/iframe";

import LogoutGuard from "@components/logout-guard";
import LoginGuard from "@components/login-guard";
import BrowserDetectGuard from "@components/browser-detect-guard";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <LogoutGuard>
        <Suspense>
          <LoginPage />
        </Suspense>
      </LogoutGuard>
    ),
  },
  // {
  //   path: "/register",
  //   element: (
  //     <LogoutGuard>
  //       <Suspense>
  //         <Registerage />
  //       </Suspense>
  //     </LogoutGuard>
  //   ),
  // },
  {
    path: "/support",
    element: (
      <Suspense>
        <SupportPage />
      </Suspense>
    ),
  },
  {
    path: "/tasks",
    element: (
      <LoginGuard>
        <Suspense>
          <TaskListPage />
        </Suspense>
      </LoginGuard>
    ),
  },
  {
    path: "/task-form",
    element: (
      <LoginGuard>
        <Suspense>
          <TaskFormPage />
        </Suspense>
      </LoginGuard>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <LoginGuard>
        <Suspense>
          <ResetPasswordPage />
        </Suspense>
      </LoginGuard>
    ),
  },
  {
    path: "/",
    element: (
      <BrowserDetectGuard>
        <Iframe />
      </BrowserDetectGuard>
    ),
  },
]);

export default router;
