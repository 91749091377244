import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "@services/user/model/user.model";

export interface GlobalStateModel {
  siderCollapsed: boolean;
  loginUser: UserModel | null;
}

const initialState: GlobalStateModel = {
  siderCollapsed: false,
  loginUser: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleSideBar: (state) => {
      state.siderCollapsed = !state.siderCollapsed;
    },
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.loginUser = action.payload;
    },
    deleteUser: (state) => {
      state.loginUser = null;
    },
    changeRedDot: (state, action: PayloadAction<number>) => {
      if (state.loginUser) {
        // state.loginUser.red_dot = action.payload;
      }
    },
  },

  extraReducers: (builder) => {},
});

export const { toggleSideBar, setUser, deleteUser, changeRedDot } =
  globalSlice.actions;

export default globalSlice.reducer;
