import { useState, useLayoutEffect } from "react";
import { debounce } from "lodash";

const Iframe = () => {
  const [height, setHeight] = useState("844px");

  function computeHeight() {
    const innerHeight = window.innerHeight;
    if (innerHeight >= 844) {
      setHeight("844px");
    } else {
      setHeight("100%");
    }
  }

  useLayoutEffect(() => {
    computeHeight();

    const cb = debounce(() => {
      computeHeight();
    }, 100);
    window.addEventListener("resize", cb);

    return () => {
      window.removeEventListener("resize", cb);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "white",
        height: "100vh",
        alignItems: "center",
        backgroundColor: "#fafafa",
      }}
    >
      <iframe
        style={{
          // height: "844px",
          height: height,
          width: "390px",
          border: "none",
        }}
        src="/tasks"
      />
    </div>
  );
};

export default Iframe;
